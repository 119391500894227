import request from '@/utils/request';

//发送验证码
export function smsSendCode(data) {
  return request({
    url:'/sms/sendCode',
    method : 'post',
    data : data
  })
}

//校验验证码
export function smsVerifyCode(data) {
  return request({
    url :'/sms/verifyCode',
    method : 'post',
    data : data
  })
}

//续费时校验手机验证码
export function smsVerifyPhoneCode(data) {
  return request({
    url :'/sms/smsVerifyPhoneCode',
    method : 'post',
    data : data
  })
}
