<template>
  <div class="login_container">
    <div class="login_box">
      <!-- 头像区域 -->
      <div style="font-size: 30px; text-align: center; padding: 20px 0; color: #333">找回密码</div>
      <el-form ref="form" size="normal" class="login_form" >
        <el-form-item prop="mobile">
          <el-input prefix-icon="el-icon-mobile" v-model="mobile" placeholder="请输入手机号"></el-input>
        </el-form-item>
        <el-form-item>
          <div style="display: flex">
            <el-input prefix-icon="el-icon-key" v-model="validCode" style="width: 65%;" placeholder="请输入验证码"></el-input>
            <div v-if="messageCodeVis" style="margin-left: 25px;" class="second-text">{{ countdown }}秒后重新获取
            </div>
            <el-button v-else type="primary" style="margin-left: 25px;" @click="sendCode()">获取验证码</el-button>
          </div>
        </el-form-item>
        <el-form-item>
          <el-button style="width: 100%" type="primary" @click="handleNext()">下一步</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="text" @click="$router.push('/login')">前往登录 >></el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
  <el-footer style="padding: 0px;height: 60px;position: absolute;bottom:0px;width:100%;">
    <Footer />
  </el-footer>

  <el-dialog title="修改密码" v-model="dialogVisibleMobile" :close-on-click-modal="false"
             width="500px">
    <div>
      <el-form ref="form" size="normal">
        <el-form-item prop="password">
          <el-input prefix-icon="el-icon-lock" v-model="password" show-password placeholder="请输入密码"></el-input>
        </el-form-item>
        <el-form-item prop="confirm">
          <el-input v-model="confirmPassword" prefix-icon="el-icon-lock" show-password
                    placeholder="请再次输入密码"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button style="width: 100%" type="primary" @click="FindPass">确 定</el-button>
        </el-form-item>
      </el-form>
    </div>
  </el-dialog>


</template>

<script>
import {smsSendCode, smsVerifyCode} from "@/api/sms";
import {updateUserPwd} from "@/api/login";
import Footer from "@/components/Footer";

export default {
  name: "FindPass",
  components: {
    Footer
  },
  data() {
    return {
      messageCodeVis: false,
      countdown: 0,
      mobile: "",
      validCode: '',

      dialogVisibleMobile: false,
      password: '',
      confirmPassword: '',
    }
  },
  methods: {
    //发送手机验证码
    sendCode() {
      if (!this.mobile) {
        /*this.$message({
          type: "error",
          message: "请输入手机号码"
        })*/
        return;
      }
      const reg = /^1[3456789]\d{9}$/;
      if (!reg.test(this.mobile)) {
        /*this.$message({
          type: "error",
          message: "请输入有效的手机号码"
        })*/
        return
      }
      this.countdown = 120
      this.messageCodeVis = true
      this.startCountdown();
      this.handleSendCode();
    },
    /* 倒计时 */
    startCountdown() {
      const intervalId = setInterval(() => {
        if (this.countdown > 0) {
          this.countdown--;
        } else {
          clearInterval(intervalId);
          this.messageCodeVis = false;
        }
      }, 1000)
    },
    // 发送手机验证码
    handleSendCode() {
      let data = {
        "mobile": this.mobile,
      };
      smsSendCode(data).then(response => {
        // 支付成功后的页面跳转
        if (response.code === '0') {
          /*this.$message({
            message: '验证码发送成功',
            type: 'success',
          });*/
        } else {
          this.$message({
            type: "error",
            message: response.msg,
            duration:5000
          })
        }
      })
    },
    //下一步
    handleNext() {
      if (!this.mobile) {
        /*this.$message({
          type: "error",
          message: "请输入手机号码"
        })*/
        return;
      }
      const reg = /^1[3456789]\d{9}$/;
      if (!reg.test(this.mobile)) {
        /*this.$message({
          type: "error",
          message: "请输入有效的手机号码"
        })*/
        return
      }
      if (!this.validCode) {
        /*this.$message({
          type: "error",
          message: "请输入验证码"
        })*/
        return;
      }
      this.handleSmsVerifyCode();
    },
    // 校验手机验证码
    handleSmsVerifyCode() {
      let data = {
        "mobile": this.mobile,
        "validCode": this.validCode,
      };
      smsVerifyCode(data).then(response => {
        // 支付成功后的页面跳转
        if (response.code === '0') {
          this.dialogVisibleMobile = true;
        } else {
          this.$message({
            type: "error",
            message: response.msg
          })
        }
      })
    },
    FindPass() {
      if (this.password !== this.confirmPassword) {
        this.$message({
          type: "error",
          message: '2次密码输入不一致！'
        })
        return
      }
      let updData =  {
        "password": this.password,
        "mobile": this.mobile,
      };
      updateUserPwd(updData).then(res => {
        if (res.code === '0') {
          this.$message.success('密码重置成功')
          this.$router.push("/")
        } else {
          this.$message.error(res.msg)
        }
      })
    },

  }
}
</script>

<style scoped>
.login_box {
  width: 500px;
  height: 380px;
  background: #fff;
  border-radius: 3px;
  box-shadow: 0 0 10px #ddd;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}


/*手机*/
@media screen and (max-width:600px){
  .login_box {
    width: 400px;
  }
}
/*平板*/
@media screen and (min-width:600px) and (max-width:960px){
  .login_box {
    width: 500px;
  }
}
/*PC*/
@media screen and (min-width:960px){
  .login_box {
    width: 500px;
  }
}

.login_form {
  position: absolute;
  bottom: 5%;
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;
}

.second-text {
  color: #e60707;
}
</style>
